
import Vue from "vue";
import VcBtn from "@/components/base/Button.vue";

export default Vue.extend({
  name: "VcHeader",
  components: {VcBtn},
  data() {
    return {
      logoSVG: require('@/assets/images/logo_black.svg')
    }
  }
})
