import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#FFF',
                secondary: '#000',
                accent: '#E10600',
                anchor: '#000',
            },
        },
    }
});
