import Vue from 'vue';
import Bus from './EventBus';
import Header from './components/header/Header.vue'
import vuetify from './plugins/vuetify'

Bus.$on('init', (element: string) => {
  const app = new Vue({
    vuetify,
    render: h => h(Header)
  }).$mount(element);
});

if (process.env.NODE_ENV === 'development') {
  Bus.$emit('init', '#header')
} else {
  ((window, a) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const objId: any = window['NavWidget'];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const queue = window[objId].q;
    queue.forEach((q: any) => Bus.$emit(q[0], ...Object.values(q).slice(1)));
  })(window)
}
